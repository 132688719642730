// ** Packages **
import { startOfDay } from "date-fns";
import { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller } from "react-hook-form";

// ** Redux **

// ** Components **
import Label from "components/FormField/components/common/Label";
import HelperText from "components/FormField/components/common/HelperText";
import Icon from "components/Icon";

// ** Types **
import { ReactDateRangePropsTypes } from "components/FormField/types/formField.types";

// ** Helper **
import { reactDatePickerSelectedDate } from "components/FormField/helper";

interface DateRange {
  startDate: Date | null;
  endDate: Date | null;
}

export const DateRangeField = <TFormValues extends Record<string, unknown>>(
  props: ReactDateRangePropsTypes<TFormValues>
) => {
  const {
    icon,
    name,
    label,
    value,
    errors,
    control,
    maxDate,
    minDate,
    register,
    selected,
    rangeDays = null,
    iconClass = "",
    labelClass = "",
    errorClass = "",
    dateFormat = "",
    placeholder = "",
    required = false,
    disabled = false,
    wrapperClass = "",
    isIconRight = false,
    showTimeSelect = false,
    fieldBGClassName = "",
    fieldWrapperClassName = "",
    popperPosition = "fixed",
    popperPlacement = "bottom-start",
    showYearDropdown = true,
    showMonthDropdown = true,
    startDate = null,
    endDate = null,
    onChange,
    getOnChangeDateValue,
    clearFieldValue,
    clearable = true,
    dropdownMode,
  } = props;
  const isFormField = control && name;

  const formGroupClassName = `${errors?.message ? "field__has__error" : ""} ${
    disabled ? "disable" : ""
  } ${fieldWrapperClassName}`;

  const wrapperClassNames = `${icon ? "field__has__icon" : ""} ${
    isIconRight ? "icon__right" : ""
  } ${wrapperClass}`;

  const dateRef = useRef<HTMLDivElement>(null);
  const defaultDateFormat = dateFormat || "MM-dd-yyyy";

  const [range, setRange] = useState<DateRange>({
    startDate: null,
    endDate: null,
  });

  // ** For clear field Value
  useEffect(() => {
    if (clearFieldValue) {
      setRange({
        endDate: null,
        startDate: null,
      });
    }
  }, [clearFieldValue]);

  useEffect(() => {
    let elm: Element | null;
    if (showTimeSelect && dateRef.current) {
      elm = dateRef.current.querySelector(
        ".react-datepicker__navigation--previous"
      );
      if (
        !elm?.classList.contains(
          "react-datepicker__navigation--prev--with-time"
        )
      ) {
        elm?.classList.add("react-datepicker__navigation--prev--with-time");
      }
    }
    return () => {
      elm?.classList.remove("react-datepicker__navigation--prev--with-time");
    };
  });
  //  For clear field Value **

  useEffect(() => {
    const newRange = {
      startDate: startDate ? new Date(startDate) : range.startDate,
      endDate: endDate ? new Date(endDate) : range.endDate,
    };
    setRange(newRange);
  }, [startDate, endDate]);

  const getInnerChangeValue = (e: [Date | null, Date | null]) => {
    const newRange = {
      startDate: e[0] ? startOfDay(e[0]) : null,
      endDate: e[1] ? startOfDay(e[1]) : null,
    };
    setRange(newRange);
    let newDate = "";
    if (
      newRange.startDate instanceof Date &&
      !(newRange.endDate instanceof Date)
    ) {
      newDate = `${startOfDay(newRange.startDate).toISOString()}_`;
    }
    if (
      !(newRange.startDate instanceof Date) &&
      newRange.endDate instanceof Date
    ) {
      newDate = `_${startOfDay(newRange.endDate).toISOString()}`;
    }
    if (
      newRange.startDate instanceof Date &&
      newRange.endDate instanceof Date
    ) {
      newDate = `${startOfDay(newRange.startDate).toISOString()}_${startOfDay(
        newRange.endDate
      ).toISOString()}`;
    }
    return newDate;
  };

  const getStartDateValue = (innerValue?: string) => {
    if (
      innerValue &&
      typeof innerValue === "string" &&
      innerValue?.split("_")?.length === 2 &&
      innerValue.split("_")?.[0]
    ) {
      return new Date(innerValue.split("_")?.[0]);
    }
    if (innerValue && innerValue.length && typeof innerValue === "object") {
      return new Date(innerValue[0]);
    }
    return range.startDate ?? null;
  };

  const getEndDateValue = (innerValue?: string) => {
    if (
      innerValue &&
      typeof innerValue === "string" &&
      innerValue?.split("_")?.length === 2 &&
      innerValue.split("_")?.[1]
    ) {
      return new Date(innerValue.split("_")?.[1]);
    }
    if (innerValue && innerValue.length && typeof innerValue === "object") {
      return new Date(innerValue[1]);
    }
    return range.endDate ?? null;
  };

  const isWithinRange = (date: Date | null) => {
    let diff = 0;
    if (date && range.startDate && rangeDays) {
      diff =
        (date.getTime() - range.startDate.getTime()) / (1000 * 60 * 60 * 24);
      diff = diff === 0 ? diff : diff + 1;
      return diff >= 0 && diff <= rangeDays;
    }
    return true;
  };

  return (
    <div className={`field__wrapper ${formGroupClassName}`}>
      {label && (
        <Label label={label} required={required} labelClass={labelClass} />
      )}
      <div
        ref={dateRef}
        className={`field__inner__wrapper ${wrapperClassNames}`}
      >
        {isFormField ? (
          <Controller
            name={name}
            control={control}
            render={({
              field: { onChange: innerOnChange, value: innerValue },
            }) => (
              <ReactDatePicker
                isClearable={clearable}
                autoComplete="off"
                className="input__carpet"
                scrollableYearDropdown
                scrollableMonthYearDropdown
                {...(dropdownMode && { dropdownMode: "select" })}
                {...(rangeDays && { filterDate: isWithinRange })}
                {...(minDate && { minDate })}
                {...(maxDate && { maxDate })}
                {...(disabled && { disabled })}
                {...(register && { register })}
                {...(placeholder && { placeholder })}
                {...(popperPlacement && { popperPlacement })}
                {...(showYearDropdown && { showYearDropdown })}
                {...(showMonthDropdown && { showMonthDropdown })}
                dateFormat={defaultDateFormat}
                onChange={(e) => {
                  getOnChangeDateValue?.(
                    dateRef.current?.querySelector("input")
                  );
                  innerOnChange(getInnerChangeValue(e));
                }}
                placeholderText={
                  placeholder.length
                    ? placeholder
                    : defaultDateFormat.toUpperCase()
                }
                popperProps={{
                  ...(popperPosition && { strategy: popperPosition }),
                }}
                startDate={getStartDateValue(innerValue as string)}
                endDate={getEndDateValue(innerValue as string)}
                selectsRange
                onCalendarOpen={() => {
                  if (showTimeSelect && dateRef.current) {
                    const elm = dateRef.current.querySelector(
                      ".react-datepicker__navigation--previous"
                    );

                    if (
                      !elm?.classList.contains(
                        "react-datepicker__navigation--prev--with-time"
                      )
                    ) {
                      elm?.classList.add(
                        "react-datepicker__navigation--prev--with-time"
                      );
                    }
                  }
                }}
              />
            )}
          />
        ) : (
          <ReactDatePicker
            isClearable={clearable}
            autoComplete="off"
            scrollableYearDropdown
            className="input__carpet"
            {...(value && { value })}
            scrollableMonthYearDropdown
            {...(dropdownMode && { dropdownMode: "select" })}
            {...(minDate && { minDate })}
            {...(maxDate && { maxDate })}
            {...(control && { control })}
            {...(disabled && { disabled })}
            {...(register && { register })}
            {...(placeholder && { placeholder })}
            {...(popperPlacement && { popperPlacement })}
            {...(showYearDropdown && { showYearDropdown })}
            {...(showMonthDropdown && { showMonthDropdown })}
            dateFormat={defaultDateFormat}
            placeholderText={
              placeholder.length ? placeholder : defaultDateFormat.toUpperCase()
            }
            onChange={(e) => {
              getOnChangeDateValue?.(dateRef.current?.querySelector("input"));
              onChange?.(e);
            }}
            selected={reactDatePickerSelectedDate(
              value || selected || undefined
            )}
            popperProps={{
              ...(popperPosition && { strategy: popperPosition }),
            }}
            selectsRange
            startDate={range.startDate || null}
            endDate={range.endDate || null}
            onCalendarOpen={() => {
              if (showTimeSelect && dateRef.current) {
                const elm = dateRef.current.querySelector(
                  ".react-datepicker__navigation--previous"
                );

                if (
                  !elm?.classList.contains(
                    "react-datepicker__navigation--prev--with-time"
                  )
                ) {
                  elm?.classList.add(
                    "react-datepicker__navigation--prev--with-time"
                  );
                }
              }
            }}
          />
        )}
        {label && (
          <Label label={label} required={required} labelClass={labelClass} />
        )}
        <span className={`bgWrapper ${fieldBGClassName}`} />
        {icon && <Icon name={icon} className={iconClass} />}
      </div>
      {errors?.message && (
        <HelperText
          helperText={errors?.message || ""}
          helperTextClass={`error__text ${errorClass}`}
        />
      )}
    </div>
  );
};

export default DateRangeField;
